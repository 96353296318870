import React, { useState, useEffect } from 'react';
import { calculateTotalExperience } from '../../../../utils/dateUtils';
import CircleIcon from '@mui/icons-material/Circle';
import circleWithGreenDot from '../../../../assets/svgs/circle-with-green-dot.svg';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import UpdateButton from '../UpdateButton';
import { Button, IconButton } from '@mui/material';
import CertificationAdd from './CertificationAdd';
import DeleteIcon from '../../../../assets/svgs/delete.svg'; // Import custom delete SVG

// Component to edit Certifications & Achievements
const CertificationEdit = ({ workData, handleClickUpdate, achievements }) => {
  const [workExperiences, setWorkExperiences] = useState([]);
  const [openWork, setOpenWorkAdd] = useState(false);
  const [achievementList, setAchievementList] = useState(achievements || []);
  const [errors,setErrors]=useState({})

  // Initialize workExperiences from workData
  useEffect(() => {
    if (workData) {
      setWorkExperiences(workData);
    }
  }, [workData]);

  const handleFieldChange = (index, field, value) => {
    const updatedExperiences = workExperiences.map((work, idx) =>
      idx === index ? { ...work, [field]: value } : work
    );
    setWorkExperiences(updatedExperiences);
  };

  const validateFields = () => {
    const fieldErrors = {};
    workExperiences.forEach((work, index) => {
      fieldErrors[index] = {
        location: !work.location,
        name: !work.name,
        startDate: !work.startDate,
        endDate: !work.endDate,
        organization: !work.organization ,
      };
    });
    setErrors(fieldErrors);
    return !Object.values(fieldErrors).some((error) => Object.values(error).includes(true));
  };

  const handleClickUpdateButton = () => {

    if(validateFields()){
    handleClickUpdate(
      'Certification & Achievements',
      [{ certifications: workExperiences, achievements: achievementList }]
    );
  }
  };

  const handleCloseWorkAdd = () => setOpenWorkAdd(false);

  const handleAddWorkSubmit = (newCert) => {
    if (Object.keys(newCert).length > 0) {
      setWorkExperiences([...workExperiences, newCert]);
    }
    setOpenWorkAdd(false);
  };

  const handleDeleteWorkExperience = (index) => {
    const updatedExperiences = [...workExperiences];
    updatedExperiences.splice(index, 1);
    setWorkExperiences(updatedExperiences);
  };

  return (
    <div className="work-experience-items">
      <CertificationAdd open={openWork} onClose={handleCloseWorkAdd} handleSumbit={handleAddWorkSubmit} />
      <Button
        variant="outlined"
        size="small"
        onClick={() => setOpenWorkAdd(true)}
        style={{
          color: '#062B6E',
          fontFamily: 'Poppins',
          fontSize: '13px',
          fontWeight: 500,
          letterSpacing: '0.2px',
          alignSelf: 'end', // Align button to right end
        }}
      >
        Add Certificate
      </Button>
      {workExperiences.map((work, index) => (
        <WorkExperienceItemEdit
          key={index}
          work={work}
          index={index}
          onFieldChange={handleFieldChange}
          handleDelete={() => handleDeleteWorkExperience(index)}
          errors={errors[index] || {}} 
        />
      ))}
      <Achievements achievements={achievementList} setAchievements={setAchievementList} />
      <UpdateButton name="summary" handleClickUpdateButton={handleClickUpdateButton} />
    </div>
  );
};

// Individual work experience item editor
const WorkExperienceItemEdit = ({ work, index, onFieldChange, handleDelete,errors }) => {
  const handleStartDateChange = (value) => {
    onFieldChange(index, 'startDate', value);
  };

  const handleEndDateChange = (value) => {
    onFieldChange(index, 'endDate', value);
  };

  return (
    <div className="work-experience-item" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <div className="item-container" style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <div className="designation-main" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
          <div className="designation" style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <div className="circle-with-green-dot">
              <img src={circleWithGreenDot} alt="circle-with-green-dot" />
            </div>

            <TextField
              value={work?.name || ''}
              onChange={(e) => onFieldChange(index, 'name', e.target.value)}
              variant="outlined"
              error={!!errors.name}
              size="small"
              style={{ width: '220px', marginRight: '12px' }}
              InputProps={{
                style: {
                  color: '#212121',
                  fontFamily: 'Poppins',
                  fontSize: '18px',
                  fontWeight: 600,
                  letterSpacing: '0.2px',
                  textAlign: 'center',
                },
              }}
            />

            <div className="dot">
              <CircleIcon style={{ color: 'green', fontSize: '10px' }} />
            </div>

            <Stack direction="row" spacing={2} alignItems="center" gap="12px" marginLeft={2}>
              <TextField
                label="Start Date"
                error={!!errors.startDate}
                type="date"
                value={work.startDate || ''}
                onChange={(e) => handleStartDateChange(e.target.value)}
                InputLabelProps={{ shrink: true }}
                size="small"
                style={{ width: '125px', marginRight: '12px' }}
              />

              <TextField
                label="End Date"
                error={!!errors.endDate}
                type="date"
                value={work.endDate || ''}
                onChange={(e) => handleEndDateChange(e.target.value)}
                InputLabelProps={{ shrink: true }}
                size="small"
                style={{ width: '125px', marginRight: '12px' }}
              />
            </Stack>
            <Stack sx={{display:'flex',alignSelf:'flex-end',marginLeft:'auto',marginBottom:'10px'}}>
            <IconButton aria-label="delete" sx={{ padding: 0 }} onClick={() => handleDelete(index)}>
            <img src={DeleteIcon} alt="delete" style={{ width: '25px', height: '25px' }} />
          </IconButton>
          </Stack>
          </div>
          <TextField
            value={work.organization || ''}
            error={!!errors.organization}
            onChange={(e) => onFieldChange(index, 'organization', e.target.value)}
            variant="outlined"
            size="small"
            style={{ width: '200px', textAlign: 'center' }} // Center alignment of TextField
            placeholder="Add Organization"
            InputProps={{
              inputProps: {
                style: {
                  color: '#4F4F4F',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: 'normal',
                  letterSpacing: '0.2px',
                  textAlign: 'left',
                },
              },
            }}
          />

          <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <span
              style={{
                color: '#4F4F4F',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: 500,
                letterSpacing: '0.2px',
              }}
            >
              Location:
            </span>

            <TextField
             error={!!errors.location}
              value={work.location || ''}
              onChange={(e) => onFieldChange(index, 'location', e.target.value)}
              variant="outlined"
              size="small"
              style={{ width: '200px' }}
              InputProps={{
                style: {
                  color: '#4F4F4F',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: 500,
                  letterSpacing: '0.2px',
                  textAlign: 'left',
                },
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
// Achievements component
const Achievements = ({ achievements, setAchievements }) => {
  const [inputValue, setInputValue] = useState(
    achievements.map((achievement) => `• ${achievement}`).join("\n")
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      setInputValue((prevValue) => prevValue + "\n• ");
    }
  };

  const handleBlur = () => {
    const newAchievements = inputValue
      .split("\n")
      .map((line) => line.replace(/^•\s*/, "").trim())
      .filter((line) => line !== "");
    setAchievements(newAchievements);
  };

  return (
    <div style={{ marginTop: "20px" }}>
      <h3>Achievements</h3>
      <TextField
        multiline
        fullWidth
        minRows={4}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        placeholder="Add achievements, press Enter to add a new line with a bullet."
        variant="outlined"
        InputProps={{
          style: {
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: 400,
            color: "#4F4F4F",
          },
        }}
      />
    </div>
  );
};

export default CertificationEdit;
