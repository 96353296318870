import { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EditIcon from '@mui/icons-material/Edit'; 
import NoDataScreen from "../../../Pages/CandidateProfile/CandidateInfo/NoDataScreen/NoDataScreen";

export const Accordion = ({ accordionItems: accordionItemsData, classes, show, handleClickUpdate, editClicked, handleEditClicked }) => {
  const [accordionItems, setAccordionItems] = useState([]);
  const [index, setIndex] = useState(show === 0 ? show : null);

  const handleHeaderClick = (idx) => {
    idx === index ? setIndex(null) : setIndex(idx);
  };

  const handleEditHeaderClick = (idx) => {
    setIndex(idx);
  };

  useEffect(() => {
    if (accordionItemsData) {
      setAccordionItems(accordionItemsData);
    }
  }, [accordionItemsData]);

  return (
    <>
      {accordionItems.map((item, idx) => (
        <AccordionItem
          key={idx}
          header={item?.header}
          Body={item?.Body}
          showBody={idx === index}
          handleHeaderClick={() => handleHeaderClick(idx)}
          handleEditHeaderClick={() => handleEditHeaderClick(idx)}
          classes={classes}
          BodyEdit={item?.BodyEdit}
          handleClickUpdate={handleClickUpdate}
          initialEditClickedState={editClicked}
          handleEditClicked={handleEditClicked}
          item={item}
        />
      ))}
    </>
  );
};

const AccordionItem = ({
  header,
  Body,
  showBody = false,
  handleHeaderClick = () => {},
  handleEditHeaderClick = () => {},
  classes,
  BodyEdit,
  initialEditClickedState,
  handleEditClicked,handleClickUpdate,
  item
}) => {
  const [editClicked, setEditClicked] = useState(initialEditClickedState);

  useEffect(() => {
    if (initialEditClickedState) {
      setEditClicked(initialEditClickedState);
    }
  }, [initialEditClickedState]);

  const isEditClicked = (name) => {
    const foundItem = editClicked.find((item) => item.hasOwnProperty(name));
    return foundItem ? foundItem[name] : false;
  };

  return (
    <div className={`${classes?.main}`}>
      <div className={`${classes?.header}`} style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <span onClick={handleHeaderClick}>{header}</span>
          {!item?.isEmpty() && !isEditClicked(header) && (!(item?.isEmptyAchievements()|| false)) && (
            <EditIcon
              style={{ marginLeft: '8px', fontSize: '20px', position: 'relative', top: '-2px', cursor: 'pointer' }}
              onClick={() => { handleEditClicked(header); handleEditHeaderClick(); }}
            />
          )}
        </span>
        {showBody ? (
          <KeyboardArrowUpIcon onClick={handleHeaderClick} />
        ) : (
          <KeyboardArrowDownIcon onClick={handleHeaderClick} />
        )}
      </div>

      {showBody && (
  item?.isEmpty() ? ( // Call `isEmpty` as a function if it’s defined as one
   <NoDataScreen header={item?.header || ""} handleClickUpdate={handleClickUpdate}/>
  ) : (
    <div className={`${classes?.body}`}>
      {!isEditClicked(header) ? <Body /> : <BodyEdit />}
    </div>
  )
)}

    </div>
  );
};
